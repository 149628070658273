import * as FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

import { Fingerprint } from "./types";

let fingerprint: Fingerprint;

const initFingerprint = async () => {
  const agent = await FingerprintJS.load({
    apiKey: process.env.REACT_APP_FINGERPRINT || "",
    endpoint: [FingerprintJS.defaultEndpoint],
    scriptUrlPattern: [FingerprintJS.defaultScriptUrlPattern],
    region: "eu",
  });
  fingerprint = agent;
  return agent;
};

const getFingerprint = async () => {
  try {
    if (fingerprint) {
      return await fingerprint.get();
    } else {
      const agent = await initFingerprint();
      return await agent.get();
    }
  } catch {
    return void 0;
  }
};

initFingerprint();

export default getFingerprint;
