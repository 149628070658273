import { Dispatch } from "modules/redux/store";
import { push } from "redux-first-history";
import { api, API } from "modules/api";

import { modalActionShow, modalActionHide } from "modules/modals/actions";
import { QuoteApiResponse, QuoteApiResponseResult } from "./types";
import { PrecisApiResponseResult } from "modules/precis/types";
import { riskActionUpdateCart } from "modules/risk/actions";
import { getFingerprint } from "modules/fingerprint";
import { ApiErrorResponse } from "modules/api/types";
import { appActionError } from "modules/app/actions";
import { ModalType } from "modules/modals/constants";
import { OptionalExtra } from "modules/quote/types";
import { RootState } from "modules/redux/store";
import validate from "modules/validation/risk";
import { blurDocument } from "modules/utils";
import { isAxiosError } from "modules/api";
import { QuoteAction } from "./constants";
import { isError } from "modules/utils";
import { quoteRoute } from "./helpers";
import {
  riskActionRemoveOptionalExtra,
  riskActionSetVoluntaryExcess,
  riskActionAddOptionalExtra,
} from "modules/risk/actions";
import {
  QuoteActionRequestStarted,
  QuoteActionRequestSuccess,
  QuoteActionRequestFailure,
  QuoteActionUpdatePrecis,
  QuoteActionDestroy,
} from "./types";

export const quoteActionRequest = (params?: { modal?: string; type?: ModalType }) => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    const id = params?.modal
      ? params.modal
      : dispatch(modalActionShow({ type: params?.type ? params.type : ModalType.LOADING }));
    const { risk, router } = getState();
    const invalid = await validate(risk, router.location?.pathname);
    if (invalid) {
      dispatch(modalActionHide(id));
      dispatch(push(invalid));
      return;
    }
    dispatch(quoteActionRequestStarted());
    try {
      const response: QuoteApiResponse = await api(API.QUOTE_REQUEST({ risk, fingerprint: await getFingerprint() }));
      dispatch(riskActionUpdateCart(response.data.risk.cart));
      dispatch(quoteActionRequestSuccess(response.data.quote));
      dispatch(modalActionHide(id));
      quoteRoute(route => route && dispatch(push(route)), response.data.quote, router.location?.pathname);
    } catch (error) {
      dispatch(quoteActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
      dispatch(
        appActionError({
          path: getState().router.location?.pathname,
          response: isAxiosError<ApiErrorResponse>(error) ? error.response?.data : undefined,
        }),
      );
      dispatch(modalActionHide(id));
    }
  };
};

export const quoteActionAddOptionalExtra = (option: OptionalExtra) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(riskActionAddOptionalExtra(option));
    dispatch(quoteActionRequest({ type: ModalType.LOADING_REQUOTE }));
  };
};

export const quoteActionRemoveOptionalExtra = (option: OptionalExtra) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(riskActionRemoveOptionalExtra(option));
    dispatch(quoteActionRequest({ type: ModalType.LOADING_REQUOTE }));
  };
};

export const quoteActionVoluntaryExcess = (
  event: React.ChangeEvent<HTMLSelectElement> | React.FocusEvent<HTMLSelectElement>,
  isMobile: boolean,
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    isMobile && blurDocument();
    dispatch(riskActionSetVoluntaryExcess(event.currentTarget.value));
    dispatch(quoteActionRequest({ type: ModalType.LOADING_REQUOTE }));
  };
};

const quoteActionRequestStarted = (): QuoteActionRequestStarted => ({
  type: QuoteAction.REQUEST_STARTED,
});

const quoteActionRequestSuccess = (quote: QuoteApiResponseResult): QuoteActionRequestSuccess => ({
  type: QuoteAction.REQUEST_SUCCESS,
  quote: quote,
});

const quoteActionRequestFailure = (error: string): QuoteActionRequestFailure => ({
  type: QuoteAction.REQUEST_FAILURE,
  error: error,
});

export const quoteActionDestroy = (): QuoteActionDestroy => ({
  type: QuoteAction.DESTROY,
});

export const quoteActionUpdatePrecis = (data: PrecisApiResponseResult): QuoteActionUpdatePrecis => ({
  type: QuoteAction.UPDATE_PRECIS,
  data: data,
});
