import { Dispatch } from "modules/redux/store";
import { api, API } from "modules/api";

import { modalActionShow, modalActionHide } from "modules/modals/actions";
import { ConfirmationApiRequest, ConfirmationApiResponse } from "./types";
import { getFingerprint } from "modules/fingerprint";
import { ApiErrorResponse } from "modules/api/types";
import { appActionError } from "modules/app/actions";
import { ModalType } from "modules/modals/constants";
import { ConfirmationAction } from "./constants";
import { RootState } from "modules/redux/store";
import { isAxiosError } from "modules/api";
import { isError } from "modules/utils";
import {
  ConfirmationActionRequestStarted,
  ConfirmationActionRequestSuccess,
  ConfirmationActionRequestFailure,
  Confirmation,
} from "./types";

export const confirmationActionRequest = (token: ConfirmationApiRequest) => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    const id = dispatch(modalActionShow({ type: ModalType.LOADING }));
    dispatch(confirmationActionRequestStarted());
    try {
      const response: ConfirmationApiResponse = await api(
        API.CONFIRMATION_REQUEST({ token, fingerprint: await getFingerprint() }),
      );
      dispatch(confirmationActionRequestSuccess(response.data.confirmation));
      dispatch(modalActionHide(id));
    } catch (error) {
      dispatch(confirmationActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
      dispatch(
        appActionError({
          path: getState().router.location?.pathname,
          response: isAxiosError<ApiErrorResponse>(error) ? error.response?.data : undefined,
        }),
      );
      dispatch(modalActionHide(id));
    }
  };
};

const confirmationActionRequestStarted = (): ConfirmationActionRequestStarted => ({
  type: ConfirmationAction.REQUEST_STARTED,
});

const confirmationActionRequestSuccess = (confirmation: Confirmation): ConfirmationActionRequestSuccess => ({
  type: ConfirmationAction.REQUEST_SUCCESS,
  confirmation: confirmation,
});

const confirmationActionRequestFailure = (error: string): ConfirmationActionRequestFailure => ({
  type: ConfirmationAction.REQUEST_FAILURE,
  error: error,
});
