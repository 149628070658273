import moment from "moment";

import { PlatformType } from "modules/platform/context";
import { App } from "./types";

export const newApp = (): App => {
  return {
    error: {
      route: undefined,
      webReference: undefined,
    },
    fingerprint: undefined,
    loading: true,
    openingTimes: {
      monday: {
        dow: 1,
        title: "Monday",
        opens: "08:30",
        closes: "19:00",
      },
      tuesday: {
        dow: 2,
        title: "Tuesday",
        opens: "08:30",
        closes: "19:00",
      },
      wednesday: {
        dow: 3,
        title: "Wednesday",
        opens: "08:30",
        closes: "19:00",
      },
      thursday: {
        dow: 4,
        title: "Thursday",
        opens: "08:30",
        closes: "19:00",
      },
      friday: {
        dow: 5,
        title: "Friday",
        opens: "08:30",
        closes: "19:00",
      },
      saturday: {
        dow: 6,
        title: "Saturday",
        opens: "09:00",
        closes: "17:00",
      },
      sunday: {
        dow: 7,
        title: "Sunday",
        opens: "10:00",
        closes: "16:00",
      },
    },
    contact: {
      telephone: {
        sales: "0345 504 0395",
        customerService: "0345 300 4870",
      },
    },
  };
};

const htmlClasses = (classes: { add: boolean; name: string }[]): void => {
  const html = document.getElementsByTagName("html")[0];
  html &&
    classes.forEach((item: { add: boolean; name: string }) => {
      item.add ? html.classList.add(item.name) : html.classList.remove(item.name);
    });
};

export const getToday = (openingTimes: App["openingTimes"]) => {
  return openingTimes[moment().format("dddd").toLowerCase() as keyof App["openingTimes"]];
};

export const getTimes = (openingTimes: App["openingTimes"]) => {
  const weekdays = Object.values(openingTimes).filter(day => day.dow !== undefined && day.dow < 6);
  const weekend = Object.values(openingTimes).filter(day => day.dow !== undefined && (day.dow === 6 || day.dow === 7));
  const result = [];
  if (weekdays.every(day => day.opens === weekdays[0].opens && day.closes === weekdays[0].closes)) {
    result.push({ title: "Mon-Fri", opens: weekdays[0].opens, closes: weekdays[0].closes });
  } else {
    result.push(...weekdays);
  }
  if (weekend.every(day => day.opens === weekend[0].opens && day.closes === weekend[0].closes)) {
    result.push({ title: "Sat-Sun", opens: weekend[0].opens, closes: weekend[0].closes });
  } else {
    result.push(...weekend);
  }
  return result;
};

export const setupPlatform = (platform: PlatformType) => {
  htmlClasses([
    {
      add: !platform.isMobile,
      name: "platform-desktop",
    },
    {
      add: platform.isMobile,
      name: "platform-mobile",
    },
    {
      add: platform.isMobileOnly,
      name: "platform-phone",
    },
    {
      add: platform.isTablet,
      name: "platform-tablet",
    },
    {
      add: platform.isAndroid,
      name: "platform-android",
    },
    {
      add: platform.isIOS,
      name: "platform-ios",
    },
    {
      add: platform.isMacOs,
      name: "platform-mac",
    },
    {
      add: platform.isWindows,
      name: "platform-windows",
    },
    {
      add: platform.isFirefox,
      name: "browser-firefox",
    },
  ]);
};
