import { FingerprintWrappedRequest } from "modules/fingerprint";
import { MetadataType } from "modules/metadata/constants";
import { version } from "@devittinsurance/devitt-types";
import { ApiConfig } from "./types";

import { AddressValidateApiRequest } from "modules/api/addressValidate/types";
import { AccountValidateApiRequest } from "modules/api/accountValidate/types";
import { AddressSearchApiRequest } from "modules/api/addressSearch/types";
import { ConfirmationApiRequest } from "modules/confirmation/types";
import { AggregatorApiRequest } from "modules/aggregator/types";
import { VehicleApiRequest } from "modules/api/vehicle/types";
import { VrmApiRequest } from "modules/api/vrmSearch/types";
import { ProceedApiRequest } from "modules/proceed/types";
import { PrecisApiRequest } from "modules/precis/types";
import { QuoteApiRequest } from "modules/quote/types";
import { AbiApiRequest } from "modules/api/abi/types";
import { RiskApiRequest } from "modules/risk/types";

const HOST = process.env.REACT_APP_API_ROOT;

export enum ApiMethod {
  GET = "get",
  POST = "post",
}

export const API = {
  APP_REQUEST: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/bike/v0/siteinfo`,
    params: {
      version: version,
    },
  }),
  ABI_REQUEST: (abi: AbiApiRequest): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/bike/v0/abi`,
    params: {
      abi: abi,
      version: version,
    },
  }),
  ACCOUNT_VALIDATE_REQUEST: (data: AccountValidateApiRequest): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${HOST}/modulus/v1/modulus`,
    data: data,
    params: {
      version: version,
    },
  }),
  ADDRESS_SEARCH_REQUEST: (data: AddressSearchApiRequest): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${HOST}/bike/v0/address/search`,
    data: data,
    params: {
      version: version,
    },
  }),
  ADDRESS_VALIDATE_REQUEST: (data: AddressValidateApiRequest): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${HOST}/bike/v0/address/validate`,
    data: data,
    params: {
      version: version,
    },
  }),
  AGGREGATOR_REQUEST: ({ fingerprint, ...data }: FingerprintWrappedRequest<AggregatorApiRequest>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${HOST}/bike/v0/quote/recall`,
    data: data,
    params: {
      session: fingerprint?.requestId,
      version: version,
    },
  }),
  ARTICLES_REQUEST: (count: AbiApiRequest): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/bike/v0/articles/${count}`,
    params: {
      version: version,
    },
  }),
  CONFIRMATION_REQUEST: ({
    fingerprint,
    token,
  }: FingerprintWrappedRequest<{ token: ConfirmationApiRequest }>): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/bike/v0/quote/confirmation`,
    params: {
      session: fingerprint?.requestId,
      token: token,
      version: version,
    },
  }),
  DOCUMENT_REQUEST: (url: string): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}${url}`,
    params: {
      version: version,
    },
  }),
  METADATA_REQUEST: (endpoint: MetadataType): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/bike/v0/metadata/list/${encodeURIComponent(endpoint)}`,
    params: {
      version: version,
    },
  }),
  PRECIS_REQUEST: ({ fingerprint, ...data }: FingerprintWrappedRequest<PrecisApiRequest>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${HOST}/bike/v0/quote/precis`,
    data: data,
    params: {
      session: fingerprint?.requestId,
      version: version,
    },
  }),
  PROCEED_REQUEST: ({ fingerprint, ...data }: FingerprintWrappedRequest<ProceedApiRequest>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${HOST}/bike/v0/quote/proceed`,
    data: data,
    params: {
      session: fingerprint?.requestId,
      version: version,
    },
  }),
  QUOTE_REQUEST: ({ fingerprint, ...data }: FingerprintWrappedRequest<QuoteApiRequest>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${HOST}/bike/v0/quote/create`,
    data: data,
    params: {
      session: fingerprint?.requestId,
      version: version,
    },
  }),
  RISK_REQUEST: ({ fingerprint, ...data }: FingerprintWrappedRequest<RiskApiRequest>): ApiConfig => ({
    method: ApiMethod.POST,
    url: `${HOST}/bike/v0/quote/recall`,
    data: data,
    params: {
      session: fingerprint?.requestId,
      version: version,
    },
  }),
  TRUSTPILOT_REQUEST: (): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/bike/v0/trustpilot`,
    params: {
      version: version,
    },
  }),
  VEHICLE_REQUEST: (data: VehicleApiRequest): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/bike/v0/bike`,
    params: {
      make: data.make,
      model: data.model,
      year: data.year,
      cc: data.cc,
      version: version,
    },
  }),
  VRM_SEARCH_REQUEST: (vrm: VrmApiRequest): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/bike/v0/vrm/search/${encodeURIComponent(vrm)}`,
    params: {
      version: version,
    },
  }),
  VRM_VALIDATE_REQUEST: (vrm: VrmApiRequest): ApiConfig => ({
    method: ApiMethod.GET,
    url: `${HOST}/bike/v0/vrm/validate/${encodeURIComponent(vrm)}`,
    params: {
      version: version,
    },
  }),
};
