import { Dispatch } from "modules/redux/store";
import { push } from "redux-first-history";
import { api, API } from "modules/api";

import { PrecisActionRequestStarted, PrecisActionRequestSuccess, PrecisActionRequestFailure } from "./types";
import { modalActionShow, modalActionHide } from "modules/modals/actions";
import { PrecisApiResponse, PrecisApiResponseResult } from "./types";
import { quoteActionUpdatePrecis } from "modules/quote/actions";
import { getFingerprint } from "modules/fingerprint";
import { ApiErrorResponse } from "modules/api/types";
import { appActionError } from "modules/app/actions";
import { ModalType } from "modules/modals/constants";
import { ROUTE } from "modules/router/constants";
import { RootState } from "modules/redux/store";
import { isAxiosError } from "modules/api";
import { PrecisAction } from "./constants";
import { isError } from "modules/utils";

export const precisActionRequest = (params?: { modal?: string; type?: ModalType }) => {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    const id = params?.modal
      ? params.modal
      : dispatch(modalActionShow({ type: params?.type ? params.type : ModalType.LOADING }));
    const { quote, risk } = getState();
    if (!risk.cart.quoteToken) {
      dispatch(modalActionHide(id));
      dispatch(
        push(quote.webReference ? `${ROUTE.MESSAGE_QUOTE}?webReference=${quote.webReference}` : ROUTE.MESSAGE_QUOTE),
      );
      return;
    }
    dispatch(precisActionRequestStarted());
    try {
      const response: PrecisApiResponse = await api(
        API.PRECIS_REQUEST({ token: risk.cart.quoteToken, fingerprint: await getFingerprint() }),
      );
      dispatch(quoteActionUpdatePrecis(response.data));
      dispatch(precisActionRequestSuccess(response.data));
      dispatch(modalActionHide(id));
      dispatch(push(ROUTE.POLICY));
    } catch (error) {
      dispatch(precisActionRequestFailure(isError(error) ? error.message : "Unexpected error."));
      dispatch(
        appActionError({
          path: getState().router.location?.pathname,
          response: isAxiosError<ApiErrorResponse>(error) ? error.response?.data : undefined,
        }),
      );
      dispatch(modalActionHide(id));
    }
  };
};

const precisActionRequestStarted = (): PrecisActionRequestStarted => ({
  type: PrecisAction.REQUEST_STARTED,
});

const precisActionRequestSuccess = (data: PrecisApiResponseResult): PrecisActionRequestSuccess => ({
  type: PrecisAction.REQUEST_SUCCESS,
  data: data,
});

const precisActionRequestFailure = (error: string): PrecisActionRequestFailure => ({
  type: PrecisAction.REQUEST_FAILURE,
  error: error,
});
